import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import config from '../config';

createApp(App).use(router).mount('#app');

const redirect_uri = config.REDIRECT_URI;
const client_id = config.CLIENT_ID;
const client_token = config.AUTH_TOKEN;

let access_token = null;
let refresh_token = null;

window.addEventListener('load', () => {
  if (window.location.search.length > 0) {
    /** check for the ?code query on url:
         * used to get user's access token */
    handleRedirect();
  } else if (localStorage.getItem('access_token')) {
    /** only get user profile if they have an access token
        already on their local storage */
    checkUserAuthStatus();
  }
});

function onPageLoad () {
  if (window.location.search.length > 0) {
    handleRedirect();
  }
}

/** FUNCTION TO CALL BOTH THE GET AUTH
CODE AND ACCESS TOKEN FUNCTIONS */
function handleRedirect () {
  const code = getCode();
  fetchAccessToken(code);
  window.history.pushState('', '', redirect_uri); // remove query strings from url
}

/** function to get code from the url
 * which is then used to the the access token */
function getCode () {
  let code = null;
  const queryString = window.location.search;

  if (queryString.length > 0) {
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.has('error')) {
      window.location.href = 'https://spotify-insight.netlify.app/';
    } else {
      code = urlParams.get('code');
    }
  }
  return code;
}

/** get a access token for user */
function fetchAccessToken (code) {
  let body = 'grant_type=authorization_code';
  body += '&code=' + code;
  body += '&redirect_uri=' + encodeURI(redirect_uri);
  callAuthorizationApi(body);
}

/** FUNCTION TO POST THE ACCESS TOKEN REQUEST */
function callAuthorizationApi (body) {
  const TOKEN = 'https://accounts.spotify.com/api/token';
  const authBody = `Basic ${client_token}`;
  const xhr = new XMLHttpRequest();
  xhr.open('POST', TOKEN, true);
  xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
  xhr.setRequestHeader('Authorization', authBody);
  xhr.send(body);
  xhr.onload = handleAuthorizationResponse;
}

/** Function to handle the response after
 * posting the access token request */
function handleAuthorizationResponse () {
  if (this.status == 200) {
    const data = JSON.parse(this.responseText);
    if (data.access_token != undefined) {
      access_token = data.access_token;
      localStorage.setItem('access_token', access_token);
    }
    if (data.refresh_token != undefined) {
      refresh_token = data.refresh_token;
      localStorage.setItem('refresh_token', refresh_token);
    }
    window.location.reload();
    onPageLoad();
  } else {
    //
  }
}

/** function to get a new access token when the access token expires */
function refreshAccessToken () {
  refresh_token = localStorage.getItem('refresh_token');
  let body = 'grant_type=refresh_token';
  body += '&refresh_token=' + refresh_token;
  body += '&client_id=' + client_id;
  callAuthorizationApi(body);
}

const token = localStorage.getItem('access_token');

/** function to check if user has auth */
async function checkUserAuthStatus () {
  try {
    const response = await fetch('https://api.spotify.com/v1/me', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const info = await response.json();
    if (info.error) {
      if (info.error.status === 401 || info.error.message === 'The access token has expiredd') {
        refreshAccessToken();
      }
    }
  } catch (error) {
    console.error(error.message);
  }
}
